<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                dir="rtl"
                v-model="formData.arabic_title"
                :rules="textRules"
                label="arabic title"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="formData.english_title"
                :rules="textRules"
                label="english title"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="formData.sequence"
                :rules="textRules"
                label="sequence"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                v-model="formData.is_popular"
                :label="`is popular: ${formData.is_popular.toString()}`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                v-model="image"
                chips
                multiple
                label="Select Image"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="6" v-if="file">
              <div class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file.data.full_url"
                  v-if="file"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file.id, 'image')"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                v-model="logo"
                chips
                multiple
                label="Select Logo"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="6" v-if="file1">
              <div class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file1.data.full_url"
                  v-if="file1"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file1.id, 'logo')"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "record", "dialog", "collection"],
  data() {
    return {
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingLanguages: false,
      image: null,
      file: null,
      logo: null,
      file1: null,
      formData: {
        id: null,
        arabic_title: null,
        english_title: null,
        image: null,
        logo: null,
        sequence: null,
        is_popular: false,
      },
      Languages: [],
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },

  methods: {
    async removeimg(id, field) {
      const { data } = await ApiService.delete(`files/${id}`);
      console.log(field);
      this.formData[field] = null;
      // console.log(this.formData);
      this.submit();
      // let msg;
      // msg = "image deleted";
      // alert(msg);
    },
    // async submit() {
    //   let data = [
    //     {
    //       arabic_title: "إدارة الغضب",
    //       arabic_description: "أبسط الخطوات  للتعامل مع الإحباط والتهديد",
    //     },
    //     {
    //       arabic_title: "اخلع رداء التوتر",
    //       arabic_description:
    //         "كتاب اخلع رداء التوتر.. 30 طريقة مرحة للتخلص من التوتر، تأليف لويس ليفي. يحاول تقديم وسائل تساعد على التخلص من التوتر.",
    //     },
    //     {
    //       arabic_title: "إدمان التشتت في العالم الرقمي",
    //       arabic_description:
    //         'يظهر مفهوم مخ الفرد في مختلف التعاليم البوذية، وهو مؤشر واحد صغير على حقيقة أن العقل وعلاقته بالعالم أمر دُرس بعمق لآلاف السنين. كل الممارسات التأملية تدعو إلى استخدام الصمت والخلوة لتهدئة العقل. في المذكرة التمهيدية لجون دروري، كان يحث الناس: "كونوا صبورين وهادئين بما يكفي للسماح للتقاليد العريقة بفرض كلمتها... ولنسمح لأفكارنا الخاصة ومشاعرنا أن تكون أقرب إلينا بقدر أكبر مما تسمح به الحياة الخارجية... فقط حينها يستطيع المرء الدخول بشكل كامل إلى نظام الخدمات العريق واللطيف، الذي يعطي المساحة والإطار، بالإضافة إلى الإرشادات، للتأملات في آمالنا وامتناننا". الكثير من الممارسات التأملية تعتبر التأمل تهيئة للعقل لتلقي الحكمة. فالعقل المشغول لا يستطيع سماع الحكمة. وفي البوذية، يُعد الانضباط العقلي غاية في حد ذاته، أكثر من كونه وسيلة لإدراك غاية ما. يقول البوذيون إن العقل اليومي يشبه خضخضة المياه؛ تعلّم أن تجعلها هادئة، مثل سطح البحيرة الهادئة الذي يشبه المرآة المسطحة، وسيريك انعكاسها.',
    //     },
    //     {
    //       arabic_title: "ارسم مستقبلك بنفسك",
    //       arabic_description:
    //         "محاولة لتقديم وصفة تساعد الشخص في رسم خطوط مستقبله بنفسه، معتمدًا على قدراته الخاصة، لمعرفته بالإمكانات الحقيقية التي يملكها، والتي تساعده في عمل هذا أو ذاك، وبالتالي الحصول على أفضل النتائج؛ لأن المستقبل المرسوم هنا يرسمه الشخص لنفسه، ولا يحدده شخص آخر يجهل بحاله.",
    //     },
    //     {
    //       arabic_title: "اقهر الاكتئاب ",
    //       arabic_description:
    //         'عندما يصاب الإنسان بالاكتئاب، فإن آخر شيء سيشعر بالرغبة فيه هو بذل الجهد في القراءة. ولكن الكتاب الذي بين يديك يختلف عن غيره، حيث إنه كتاب داعم، وضعه مؤلف عانى ما تعانيه من اكتئاب، ولكنه نجح في تجاوز تلك المرحلة. كتاب "اقهر الاكتئاب" ليس محاولة لشرح حالة الاكتئاب أو تنظيرها، شأن معظم الكتب التي عالجت الموضوع، بل إنه يدخل إلى قلب المشكلة مباشرة، ويأخذ القارئ بخطوات متأنية متمهلة في طريق التعافي والسعادة.',
    //     },
    //     {
    //       arabic_title: "الأفكار والمشاعر",
    //       arabic_description:
    //         "يتحدث عن مجموعة من المشكلات النفسية، قسمها الي فصول مع  علاجها، بحيث إنك تختار بسهولة الفصل الذي تحتاجه، وتبدأ في قراءته حسب مشكلتك، وهو مليء بالتمارين العملية.",
    //     },
    //     {
    //       arabic_title: "الابتزاز العاطفي",
    //       arabic_description:
    //         "الابتزاز العاطفي، من منّا من لم يمارَس عليه أو قام هو به؟  والمضحك أننا نفعل ذلك بدون شعور، وأحيانًا ننفذ ما يريده المبتز منّا على حساب مشاعرك؛ وذلك لقربه منك وأنك تريد إسعاده!  ",
    //     },
    //     {
    //       arabic_title: "التعامل مع من لا تطيقهم",
    //       arabic_description:
    //         'لا تخلو حياتنا من أشخاص محيطين بنا صعاب المراس، قد تجد نفسك ضحية أحدهم، أو يشعرك شخص ما بالذنب، أم تستمع لنصائح من شخص متطفل، وغيرها. هذا الكتاب هو الحل العملي للتعامل مع هؤلاء. نقتبس لكم آخر جملة وردت في الكتاب: "إن الحياة ليست اختبارًا، إنها حالة طارئة حقيقية".',
    //     },
    //     {
    //       arabic_title: "الثقة التامة بالنفس",
    //       arabic_description:
    //         "كتاب عن واحدة من أهم الصفات التي يفتقدها الكثير، وهي الثقة بالنفس، التي بدونها نخسر الكثير من الفرص في حياتنا. يعلمنا هذا الكتاب طرقًا مختلفة؛ لنثق في ذواتنا وقدرتنا على النجاح وتخطي الصعاب.",
    //     },
    //     {
    //       arabic_title: "الشخص مفرط الحساسية",
    //       arabic_description:
    //         "دليل عملي لحل تلك المشكلة لدى من يعانونها، يبدأ الكتاب باختبار ذاتي، وبعدها مناقشة الحقائق عن تلك الشخصية. ",
    //     },
    //     {
    //       arabic_title: "الصداقة والصديق ",
    //       arabic_description:
    //         "موسوعة أدبية غنية عما قيل في الصداقة من شعر، وقصص، ورسائل، بأسلوب حكيم جذاب فلسفي. ",
    //     },
    //     {
    //       arabic_title: "العادات السبع للمراهقين الأكثر فاعلية",
    //       arabic_description:
    //         "هذا الكتاب مليء بالقصص المدهشة، يرويها مراهقو اليوم عن أنفسهم، وهي قصص كتبت بدوافع عديدة؛ مثل الغضب، والإحباط، والمرح، والحماس، والوحدة، والنجاح، والأمل، والإلهام، والرسوم الإلكترونية، والأفكار، والاقتباسات التي يحتويها الكتاب، تجعله مثيرًا وجذابًا وموائمًا للموضوع، من الغلاف إلى الغلاف. وبالإضافة إلى هذا فإن الكتاب يضع دليلًا مفصلًا خطوة بخطوة، ليساعد المراهقين على بناء صورة الذات، وعلاقات الصداقة، ومقاومة ضغط النظراء، وتعليمهم كيفية تحقيق أهدافهم طويلة الأجل.",
    //     },
    //     {
    //       arabic_title: "العادات السبع للناس الأكثر فاعلية",
    //       arabic_description:
    //         "سبع عادات لتكن أكثر نجاحًا، كتاب يعلمك التصرف مع أغلب المشاكل العائلية المعروفة وبطريقة مذهلة. ",
    //     },
    //     {
    //       arabic_title: "الفشل البنّاء: تحويل الأخطاء إلى جسر النجاح",
    //       arabic_description:
    //         "بعض الناس يولدون لتحقيق ما يريدونه في الحياة، فما أسباب نجاحهم؟ الفرق بين الناجحين والفاشلين هو إدراكهم واستجابتهم  للفشل.",
    //     },
    //     {
    //       arabic_title: "المرونة العاطفية",
    //       arabic_description:
    //         'أطلق العنان لنفسك، وتقبل التغيير، وازدهر فى عملك. تقول الكاتبة سوزان ديفيد: "بينما نمضى فى دروب حياتنا، لا يتاح أمامنا سوى القليل من الطرق التى يمكننا من خلالها معرفة المسار الذي علينا اتخاذه أو الذي ينتظرنا".',
    //     },
    //     {
    //       arabic_title: "تغلب على الاكتئاب  ",
    //       arabic_description:
    //         'هناك سوء فهم هائل يتعرض له موضوع الاكتئاب. فأولئك الذين يقرّون بأنهم يعانون منه، كثيرًا ما يشعرون بالخجل. وأولئك الذين لم يسبق لهم مطلقاً أن عانوا منه يعتقدون أن الأمر لا يعدو أن يكون "نزلة برد أو إنفلونزا". في هذا الكتاب 52 فكرة ملهمة، كتبتها اختصاصية نفسانية اضطرت في الماضي للتعامل مع إصابتها هي شخصيًّا بالاكتئاب وهي بعدُ شابة.\nيتناول الكتاب كيفية كسر الحلقة المفرغة التي تصيب الإنسان المكتئب، وكذلك فكرة كتابة اليوميات التي تساعد في التقليل من القلق ومداواة الأحزان، وإفراغ الهموم دون خجل. الكتاب وسيلة لجعلك أكثر موضوعية في الحكم على التجارب، ويحتوي العديد من الأفكار الرائعة التي تساعد بشكل عملي وسهل.',
    //     },
    //     {
    //       arabic_title: "تغلب على الاكتئاب بسرعة",
    //       arabic_description:
    //         "هذا الكتاب موجه إلى من يعانون الاكتئاب، فهو معني بالمصابين به لأسابيع، أو شهور، أو سنوات.",
    //     },
    //     {
    //       arabic_title: "خطة الحياة: 700 طريقة بسيطة لتغير حياتك",
    //       arabic_description:
    //         "كتاب يقدم 700 وصفة سهلة ليغير الإنسان بها مسار حياته، ويحدث اختلافًا في طريقة رؤيته للأمور وتعامله معها، والطرق متنوعة وتناسب كل الشخصيات.",
    //     },
    //     {
    //       arabic_title: "فكر وازدد ثراء",
    //       arabic_description:
    //         "كتاب عن أهمية التفكير في حياتنا، حيث هو وسيلة للتطور الذاتي والحصول على صورة أفضل من ذواتنا، إنه يساعدك على الثراء والحصول على المال، لأن التفكير يجعلك تعيش حياة عقلانية نتخذ فيها قراراتك بناء على الفكر والتحليل للمواقف.",
    //     },
    //     {
    //       arabic_title: "قوة عقلك الباطن",
    //       arabic_description:
    //         "هل هناك علاقة بين صورة عقلك اللاوعي والدعاء؟  هل اليقين يحقق المرغوب؟ ما الفرق بين عقلك الوعي والباطن؟ هذا ما يجيب الكتاب عنه.",
    //     },
    //     {
    //       arabic_title: "كيف تجعل جهازك العصبي سعيدًا",
    //       arabic_description:
    //         'نقلت الصحف مؤخرًا أن "الاكتئاب هو أكثر أمراض القرن الحادي والعشرين شيوعًا، وأن الإصابة به أمر متوقع بشكل كبير". ولا يزال الاكتئاب يساء فهمه غالبًا، ولسوء الحظ لا تزال وصمة العار المرتبطة به منتشرة؛ وذلك بسبب أن الاكتئاب -كمرض- غالبًا ما يتوارى خلف مرض آخر، ولا يزال يُستخف به كمرض، مع أنه يمكن أن يعيث فسادًا في الصحة البدنية والعقلية للشخص.\nويتسبب الاكتئاب غير المشخَّص في خسائر فادحة تصيب حياة الأشخاص، وقد تضخمت تكلفة الاكتئاب غير المشخَّص إلى ما يوازي ٥ مليارات دولار في السنة، وتأتي هذه الخسائر من ضعف الإنتاجية.',
    //     },
    //     {
    //       arabic_title: "كيف تقول لا: 250 طريقة لتقولها وتعنيها",
    //       arabic_description:
    //         'كتاب يعلمنا طرقًا عديدة لقول الكلمة الأصعب، وهي كلمة "لا"، التي نعجز كثيرًا عن قولها في مواقف عديدة نحتاج فيها لقولها لمن يستغلوننا، ومن يتعاملون معنا بطرق تسيء إلينا، وتجعل حياة بائسة وحزينة.',
    //     },
    //     {
    //       arabic_title: "كيف تكسب الأصدقاء وتؤثر في الناس",
    //       arabic_description:
    //         "كتاب رائع، موسوعه في ذلك المجال، أسلوب واضح بخطوات عملية. ",
    //     },
    //     {
    //       arabic_title: "لا تكن لطيفًا أكثر من اللازم",
    //       arabic_description:
    //         "ليست دعوة للقسوة والوقاحة في التعامل مع الناس، لكن دعوة للتركيز على مصالحنا الشخصية بقدر مناسب. فاللطف الزائد عن الحد يخسر به الإنسان كثيرًا من احترامه إذا لم يقدر الآخر هذا اللطف؛ لذا فالواجب الاعتدال في المعاملة.",
    //     },
    //     {
    //       arabic_title: "لا تهتم بصغائر الأمور",
    //       arabic_description:
    //         "دعوة لتجاوز الأمور التافهة، أو الأقل أهمية؛ من أجل الاهتمام بأمور أكثر فائدة؛ لأن الحياة أثمن من أن نضيعها في أمور ليست ذات قيمة. والكتاب يتناول طرقًا ووسائل لتحقيق هذا الغرض، والابتعاد عن القلق والتوتر",
    //     },
    //     {
    //       arabic_title: "لغات الحب الخمسة",
    //       arabic_description:
    //         "إذا كان للحب لغة يتكلمها، فإن هذا الكتاب يحاول اختصارها في 5 لغات يعبر بها الحب عن نفسه، وإذا ظهرت فإنها تعبر عن وجود الحب بل وقوته، وهي كلمات التشجيع، تكريس الوقت، تبادل الهدايا، أعمال خدمية، وأخيراً الاتصال البدني.",
    //     },
    //     {
    //       arabic_title: "مصيدة التشتت: كيف تركز في فوضى العالم الرقمي",
    //       arabic_description:
    //         "يعرض هذا الكتاب طريقة الإقلاع عن إدمان الوسائل الرقمية، وكيفية إنجاز المهام على نحو لائق، وكيفية السيطرة على يومك بمنتهى الهدوء. كما سيساعدك على تحسين جودة العمل والعلاقات، وتحقيق الأهداف الموضوعة، والأهم من ذلك سيساعدك على استعادة إحساسك بآدميتك مرة أخرى.",
    //     },
    //     {
    //       arabic_title: "نجاحات عظيمة يومية",
    //       arabic_description:
    //         "يحتوي الكتاب على مجموعة من القصص المؤثرة والجذابة عن حياة الناجحين، وما مروا به من تحديات. ستجد تعليقاته على كل قصة، والمبادئ التي عملوا بها في حياتهم. ",
    //     },
    //     {
    //       arabic_title: "هذه حياتك لا وقت للتجارب",
    //       arabic_description:
    //         "كبسولة يومية بطريقة جذابة وسهلة يبين لك ماذا تريد، وإلى أين تريد الوصول، وكيفية تحديد الأهداف للوصول إليها.",
    //     },
    //     {
    //       arabic_title: "وحي القلم",
    //       arabic_description:
    //         "يتكون الكتاب من ثلاثة أجزاء، وهو عبارة عن مجموعة مقالات متنوعة، تتناول موضوعات اجتماعية وأخرى عن الحب، وعن الدفاع عما التبس من حقائق الإسلام وآدابه، وصيغت بأسلوب القصة؛ لتعطي العبر بطريقة تجذب القارئ. ",
    //     },
    //     {
    //       arabic_title: "جسمك يتكلم.. اسمعه",
    //       arabic_description:
    //         "يقدم الكتاب إجابات عن هذه الأسئلة:\nكيف تؤثر مشاكلك العاطفية والنفسية في صحتك؟\nكيف ترتبط مشاعرك وأفكارك بأعضاء معينة من جسمك؟\nكيف تشفى الجسد عن طريق النفس والنفس عن طريق الجسد؟",
    //     },
    //     {
    //       arabic_title: "أثافي الحرب",
    //       arabic_description:
    //         "مرت أجيال عدة على هذه الأحداث، واختلفت الحقيقة وراءها، فوقها تتقد وتخبو وتنطفئ آثار وهج وصراع وملحمة. تسرد تاريخ فرسان مملكة القاضي، ورماح مملكة بابانا، ورايات الباسل، وترانيم التائهين في عالم البشر.. حكاية مسير صعب حول أثافي الحرب.",
    //     },
    //     {
    //       arabic_title: "أشياء يفعلها السعداء ",
    //       arabic_description:
    //         "كتاب يحتوي على مقالات إيجابية منوعة، من واقع خبرات صاحبه والدروس التي تعلمها في حياته.",
    //     },
    //     {
    //       arabic_title: "في الهنا",
    //       arabic_description:
    //         'رواية سيرة ذاتية حقيقية، إنها صرخة موحشة، تضج بوجع ووحدة صاحبها طالب الرفاعي، تعري العوالم الخفية لعلاقة المرأة بالرجل في المجتمعات الخليجية. فتاة عزباء تهيم عشقًا برجل دولة متزوج، ويكون طالب الرفاعي، باسمه الصريح، مشاركًا في معايشة منعطفات هذه العلاقة: "أنت صديق بابا الأقرب، ولا أحد لي غيرك". كيف يمكن للحب والشوق والأمل أن يزهر في زمن الانتفاضات العربية والعنف والدم والموت. لعبة تقطيع الزمن الروائي هي العنصر الأساسي في بناء رواية «في الهنا»، فالرواية بأكملها تأتي عبر جملة واحدة موزعة على أربعة مقاطع! طالب الرفاعي في روايته الجديدة، يصرّ على السير فى درب المغامرة الحياتية الملغومة، بكتابة رواية «التخييل الذاتي» في مجتمعات لا تطيق سماع الحقيقة.',
    //     },
    //     {
    //       arabic_title: "زهرة العوسج",
    //       arabic_description:
    //         "هناك وجهات نظر بالحياة مختلفة، وزوايا لن نعرفها إلا بعد زمن. يجب البحث عن هويتنا وذواتنا. رواية العائلة والحب، زهرة العوسج تلك الزهرة الجميلة التي تنمو وسط أشواك، تحكي قصص مشكلات للبعض الفتيات بالمغرب وبجميع وطننا العربي، في العمل والدراسة.",
    //     },
    //     {
    //       arabic_title: "الحرية حلال",
    //       arabic_description:
    //         "خواطر بسيطة، ولكنها عميقة؛ لأنها تجمع بين الروحانية والوعي.",
    //     },
    //     {
    //       arabic_title: "خطيئة الخيال ",
    //       arabic_description:
    //         "قصص قصيرة.. ما وراء الطبيعة.. خيال.  رعب.. دراما.",
    //     },
    //     {
    //       arabic_title: "فاطمئن",
    //       arabic_description:
    //         "يشمل الكتاب خواطر منوعة دوّنها الكتاب، ومن هذه الخواطر توجيهات، ومنها أيضًا نصائح ومواعظ، ويستدل فيها بآيات من القرآن الكريم وأحداث من السنة النبوية.",
    //     },
    //     {
    //       arabic_title: "لأني عبدك",
    //       arabic_description:
    //         "خواطر على هامش آيات قرآنية، انتقاها الكاتب ليعلق بخاطرة على كل منها، وهي في مجملها تستحث الرضا بالله والتوكل عليه، وهي أشبه بخواطر إيمانية ذاتية عن هذه الآيات.",
    //     },
    //     {
    //       arabic_title: "متاهة حزن",
    //       arabic_description:
    //         "أحدهم سيرحل عنك ويترك لك فضاءً من الأحزان يرتع به قلبك، وستحبه بكل ما أوتيت من صدق ووفاء وإحساس، وسيهملك بكل ما أوتي من إهمال.",
    //     },
    //     {
    //       arabic_title: "مدينة الأقوياء",
    //       arabic_description:
    //         "كتاب بسيط خفيف، يتحدث عن مكارم الأخلاق وتطوير الذات. والجميل فيه استشهاد الكاتب بالآيات القرآنية والأحاديث الشريفة بموضوعاته. ",
    //     },
    //     {
    //       arabic_title: "وأصبحت أمًّا هادئة",
    //       arabic_description:
    //         "رحلة أم تحكي عن صورة لأمومة مليئة بالحب والعادة، وعن الطريق والوسائل التي يمكن للمرأة أن تجمع فيها بين الأمومة الناجحة، وبين الترقي الوظيفي في عالم مليء بالضغوط الحياتية.",
    //     },
    //     {
    //       arabic_title: "11:11",
    //       arabic_description:
    //         "رواية تعرض قصة حب، وهي أشبه بخواطر مطولة عن أن تكون رواية، ويغلب عليها التغزل بالمحبوبة ومآثرها، وتعرض للرحيل والفراق وما يحملانه من خلجات ومشاعر متضاربة.",
    //     },
    //     {
    //       arabic_title: "بلسم",
    //       arabic_description:
    //         "كتاب عن الإلهام والتحفيز الذاتي، ووسائل تحقيق الاستقرار الداخلي للفرد، ومحاولة للملمة الجراح الإنسانية عن طريق الخواطر المحفزة ذاتيًّا.",
    //     },
    //     {
    //       arabic_title: "كن قويًّا ",
    //       arabic_description:
    //         "كتاب صغير، تفتح به سهام العنزي بوابة أمل في حياة أكثر حيوية وإشراقًا، مستعينة في ذلك بسرد قصص ملهمة لشخصيات بدأت من القاع وتسلقت سُلم النجاح والإنجاز.",
    //     },
    //     {
    //       arabic_title: "إدراك الهدف",
    //       arabic_description:
    //         "كتاب تحفيزي أصدره الكاتب بعد كتابه الشهير «العادات السبع للناس الأكثر فاعلية». والكتاب هنا يركز على عادة رئيسة من هذه العادات وهي إدراك الهدف.",
    //     },
    //     {
    //       arabic_title: "افشل بفخر",
    //       arabic_description:
    //         "مجموعة من النصائح التي يقدمها الممثل الأمريكي دنزل واشنطن، لمجابهة الفشل والوصول للنجاح وتحقيق الهدف المرجو، والتعامل مع الفشل حين يحدث، بل والفخر عند الفشل بأننا حاولنا وبذلنا كل ما أمكننا.",
    //     },
    //     {
    //       arabic_title: "جبر خاطر",
    //       arabic_description:
    //         "رواية عن الحب والفراق، وعن خيانات الروح التي قد يتلقاها المرء من علاقته بشريك ما، وهي تدور في مجتمع كويتي، وتعبر عن خصوصيته الثقافية والاجتماعية، وتيمتها الرئيسة عن جبر الخاطر، والعطاء بدون انتظار للمقابل من الآخر.",
    //     },
    //     {
    //       arabic_title: "حيوات",
    //       arabic_description:
    //         "رواية تأخذنا في رحلة للبحث عن الذات المفقودة، وهي تيمة العمل الرئيسة، ومعها يطرح كويلو إشكالات البحث عن الهوية، وعن ذواتنا التائهة بين الماضي والحاضر والمستقبل.",
    //     },
    //     {
    //       arabic_title: "فن الذاكرة الخارقة",
    //       arabic_description:
    //         "كتاب يناقش وسائل تقوية الذاكرة، من منظور عملي مجرب، وكذلك من منظور علمي أكاديمي، ويستقي الكاتب هذه الوسائل من علوم وفلسفات مختلفة، ومنها العودة إلى الثقافات القديمة.",
    //     },
    //     {
    //       arabic_title: "لتصبح ناجحًا تأكد أولًا من أن لديك حلم",
    //       arabic_description:
    //         "كتاب عن أهمية أن يكون للمرء حلم، وعن دور الأحلام التي نحملها بداخلنا في توجيه بوصلة حياتنا، وكيف أن الحلم مفتاح نجاحنا في هذه الحياة، مع المثابرة وبذل المجهود.",
    //     },
    //     {
    //       arabic_title: "لا تتوقف",
    //       arabic_description:
    //         "كتاب في تنمية الذات والإبداع في كل الأوقات، جيدها وسيئها، انطلاقًا من أن الإبداع يولد من رحم المعاناة؛ لذا يقدم الكاتب ما يعتبرها 10 طرق ناجحة ومجربة؛ ليظل الإنسان مبدعًا على الدوام.",
    //     },
    //     {
    //       arabic_title: "الصداقة من منظور علم النفس",
    //       arabic_description:
    //         "دراسة الأسس النفسية لعلاقة الصداقة بين الأشخاص، كيف تبدأ الصداقة الناجحة وتستمر؟ ما المهارات الاجتماعية لاكتساب الاصدقاء؟",
    //     },
    //     {
    //       arabic_title: "سيكولوجية السعادة",
    //       arabic_description:
    //         "الكتاب لا غنى عنه لكل باحث أو مفكّر أو مدرّب يتعامل مع الناس، أو يهتم بدراسة العلوم الاجتماعية والانسانية. ما السعادة؟ كيف نحصل عليها؟  ويقسم السعادة إلى أربعة عناصر: الرضا، والمشاعر الانفعالية، والبهجة، والصحة. ويتناول أهم العناصر المساهمة في الحصول على السعادة.",
    //     },
    //     {
    //       arabic_title: "أنت تستطيع",
    //       arabic_description:
    //         "كتاب لتحفيز الذات لتنهض من كبواتها وتقتحم الحياة، لتبحث فيها عن السعادة، بغض النظر عن العثرات والفشل الذي قد يقابله الشخص فيها، لكن بإمكانه أن ينهض مرة أخرى ليحقق ما يرغب فيه.",
    //     },
    //     {
    //       arabic_title: "اخرج في موعد مع فتاة تحب الكتابة",
    //       arabic_description:
    //         "كتاب يناقش مواصفات شريكة حياة محددة، وهي الفتاة التي تكتب، وينبري الكاتب لإحصاء فضائل هذه الفتاة، وما قد تقدمه لشريكها من سعادة وبهجة. والكتاب به قدر كبير من التنميط للمرأة، ولما يجب أن تقوم به لتجذب شريكًا آخر.",
    //     },
    //     {
    //       arabic_title: "أشياء جميلة",
    //       arabic_description:
    //         "يتناول الكتاب أشياء جميلة فى حياتنا، بعضنا قد أدار ظهره إليها، والبعض الآخر لا يراها من الأساس. الصحه أولى النعم والأشياء الجميلة التي يجب أن نحمد الله عليها. الحب أيضًا إحساس مختلف جميل، تشعر بالسعادة كونك تحب الأولاد رغم التعب معهم، ولكن وجودهم حياة. أن تجعل نفسك سعيدًا معناه أن تكون راضيًا عما تعيشه.\nكتاب جميل يضم مجموعة من القصص والأحداث القصيرة (بحدود 50 قصة) من مختلف أنحاء العالم. يسعى لإعطائك طاقة إيجابية.",
    //     },
    //     {
    //       arabic_title: "الفتاة التي تحترق",
    //       arabic_description:
    //         "أب موجود وغير موجود للابنة. صديقة كانت مقربة، ولكن عند سن المراهقة ابتعدا عن بعضهما، وأصبحت العلاقة رسمية، فما سبب ذلك؟ ",
    //     },
    //     {
    //       arabic_title: "انتبه للسعادة",
    //       arabic_description:
    //         "أحاديث تتقاطع، لكنها تجتمع لترسم طريقًا للسعادة وللوصول إليها، وكيف يمكن لنا أن نتلمسها في كل ما حولنا وما بداخلنا من مشاعر، فبداخلنا تكمن السعادة التي هي وليدة الحب؛ لذا فقبل أن نبحث عن السعادة في الأشياء، يمكننا أن نبحث عنها في مكان أقرب في قلوبنا.",
    //     },
    //     {
    //       arabic_title: "تاجر السعادة",
    //       arabic_description:
    //         "سر السعادة ليس فقط أن تكون سعيدًا بمفردك، ولكن السعادة الحقيقية هي أن تعرف كيف تنشر السعادة في نفوس الآخرين، وتكون لمحيطك طاقة إيجابية، وقدوة، ونموذجًا ملهمًا، ومحفزًا، وناجحًا في عملك، في المدرسة، في الجامعة، في المجتمع.",
    //     },
    //     {
    //       arabic_title: "حب الحياة",
    //       arabic_description:
    //         "دعوة أخرى للحياة ولعيشها بطريقة لا تعقدها أكثر مما هي معقدة، بل عيشها بسلاسة وانطلاق، لاقتناص أكبر عدد ممكن من اللحظات السعيدة بها، لأنها لن تكرر.",
    //     },
    //     {
    //       arabic_title: "حديث الصباح",
    //       arabic_description:
    //         "مجموعة مقالات متفرقة أو لنقل قصصًا قصيرة لنتعلم منها العبر والعظات.",
    //     },
    //     {
    //       arabic_title: "حديث المساء",
    //       arabic_description:
    //         "قصص قصيرة ذات عبرة، تنتهي فتأتي بعدها الدروس والقيم المتعلَّم منها. كتاب خفيف ومفيد، ويعطي طاقة إيجابية بسهولة أسلوبه وحكمته. ",
    //     },
    //     {
    //       arabic_title: "حول العالم في ثمانين يومًا",
    //       arabic_description:
    //         "جولة حول العالم مستحيلة في عام 1872م، قصة رهان عجيبة، عقده بطل الرواية فيلياس مع أعضاء نادي الإصلاح في لندن، فهل سيكسب الرهان؟ ",
    //     },
    //     {
    //       arabic_title:
    //         "سنة القراءة الخطرة: كيف استطاع خمسون كتابًا عظيمًا من إنقاذ حياتي ",
    //       arabic_description:
    //         "يقرر ميللر المشغول بحياته وطفله أن يقرأ خمسين كتابًا في هذا العام،  وهو متحمس، ولا يعلم أننا القراء النشطين نقرأ أضعاف أضعاف هذا الرقم.. ولكنه خفيف ولطيف لمن أحبه! ",
    //     },
    //     {
    //       arabic_title: "علمتني آية",
    //       arabic_description:
    //         "عبارة عن خواطر حول آيات قرآنية؛ لذا فهو أشبه بالتفسير الميسر، لكنه يميل للتدبر والتأمل، وهو يجمع هذه التأملات بالأساس من كتب أئمة التفسير الكبار؛ لمساعدة القارئ على عيش حياته بالقرآن، الذي هو راحة العمر وسكينته.",
    //     },
    //     {
    //       arabic_title: "عن السفر إلى أماكن جميلة ",
    //       arabic_description:
    //         "المجموعة ثلاثة شعراء من مدارس وأشكال كتابية مختلفة، يحتوي الفصل الأول على حوار مطول مع الشاعر الأمريكي تشارلز بوكوفسكي، أجرته الصحفية الإيطالية فيرناندا بيڤانو، التي سافرت من إيطاليا إلى كاليفورنيا خصيصًا لرؤية بوكوفسكي.\nويحتوي الفصل الثاني على مجموعة مختارة من قصائد الشاعر الروماني مارين سوريسكو. كان مارين سوريسكو شاعرًا، ومسرحيًّا، وكاتب مقالات، ومترجمًا، ترجمت أعماله إلى أكثر من (20) لغة، ووصلت كتبه إلى أكثر من (60) كتابًا، وقد عرف عنه أيضًا أنه كان يرسم، وأقام عدة معارض فنية في رومانيا وخارجها، وكان وزيرًا للثقافة في رومانيا خلال فترة من حياته دون أن ينتمي إلى أي حزب سياسي، بعد الثورة الرومانية سنة ١٩٨٩م.\nأما الفصل الثالث من الكتاب فهو مصدر عنوان الكتاب «عن السفر إلى أماكن جميلة»، وهو مخصص لقصائد شاعرة الطبيعة الأمريكية ماري أوليڤر.",
    //     },
    //     {
    //       arabic_title: "عن شيء اسمه الحب",
    //       arabic_description:
    //         "رواية عن الحب من النظرة الأولى، وعما يتلبسنا حين نقابل شقيق الروح من اللحظة الأولى، عن ذلك التداعي الحر في المجهول -الحب- لنصحو على فراق يسلب النوم بقية العمر.",
    //     },
    //     {
    //       arabic_title: "عن وطن من لحم ودم",
    //       arabic_description:
    //         "رحلة للبحث عن الوطن لكن في المجاز، فالوطن هنا ليس مكانًا يبحث عنه الكاتب، لكن دفء وشعور بالطمأنينة. والكتاب مقسم إلى خواطر تدور في  الفلك ذاته.",
    //     },
    //     {
    //       arabic_title: "لا تعش حياة شخص آخر",
    //       arabic_description:
    //         "الكتاب يمثل دعوة للحياة، لعيشها ببهجة وحب، واقتناء اللحظات الثمينة بها؛ لأننا نعيشها مرة واحدة، فلزم علينا الاستمتاع بكل لحظة فيها. والكتاب مقسم لمقالات عن السعادة والحلم والحب وغيرها، وكلها مسببات للحياة، ولعيشها بطريقة صحيحة.",
    //     },
    //     {
    //       arabic_title: "ليطمئن قلبي",
    //       arabic_description:
    //         "رواية تقدم سبيلًا للإيمان الصادق بالله، إيمان يُعلى من الحدس، وتتداخل خيوط كثيرة في الرواية لتحكي عن الحب والفراق، وعن لقاء العابرين، وقصص البشر المتداخلة.",
    //     },
    //     {
    //       arabic_title: "مذهل",
    //       arabic_description:
    //         "هو كتاب عن التواصل الجيد والناجح بين الأفراد، خاصة بين شريكي الحياة، ويتحدث الكاتب باستفاضة في مقالات متنوعة تصب جميعها في إيضاح خصائص التواصل الناجح مع الآخرين، وكيف نحققه.",
    //     },
    //     {
    //       arabic_title: "مع النبي",
    //       arabic_description:
    //         "يتحدث الكاتب بأسلوب أدبي وسردي عن قصص ومواقف للرسول -صلى الله عليه وسلم- وما فيها من دروس وعبر من أبرزها:\nالامتناع عن الإساءة - من غير الصحيح الاعتقاد الدائم بأنك دائمًا على صواب - لا تطلق الأحكام دون التعمق في الأمور - على الآباء والأمهات التمهل وعدم الاستهانة بالدعاء على الأبناء - الدعاء سلاح المؤمن - الجنة طريقها: الألم، والتعلم، والصبر.",
    //     },
    //     {
    //       arabic_title: "نبأ يقين",
    //       arabic_description:
    //         "دعوة لنظرة مختلفة لحياتنا، ورؤية كل ما نمر به بشكل إيجابي، وتحسين نظرتنا لوظائفنا، وكيف نخدم بها المجتمع والناس؛ لذا فهي محاولة للرضا وتقبل كل ما لا تقبله نفوسنا.",
    //     },
    //     {
    //       arabic_title: "نبض",
    //       arabic_description:
    //         "رواية يحكي فيها كاتبها قصة حبه لفتاة اسمها نبض، وهي قصة عن الحب والفراق، وتميل للسرد الشخصي الذي يحاول التعبير عن المكنون الداخلي للشخصيات، والتعبير عن المعاناة الإنسانية في الفقد والفراق، وقبلهما في الحب.",
    //     },
    //     {
    //       arabic_title: "نطفة",
    //       arabic_description:
    //         "الرواية من نوع أدب السجون. هناك رومانسية طاغية ببعض الفقرات تميز أسلوب الكاتب، ثم يتحدث الجزء الثاني من الرواية عن كيفية نقل نطفة من السجين حمزة، لزوجته أسماء؛ ليثبت لجيش الاحتلال أن الحرية لا تُباع، إنما هي فطرة.",
    //     },
    //     {
    //       arabic_title: "وتلك الأيام",
    //       arabic_description:
    //         "كتاب في تداول الأيام بين الناس، وعن أن الدنيا لا تدوم على حال، ويقسم الكاتب كتابه إلى أيام السنة، وفي كل يوم يستذكر له حدثًا تاريخيًّا، والكتاب إجمالًا يستحث التذكر وإدارك السنن الكونية.",
    //     },
    //     {
    //       arabic_title: "حارس سطح العالم",
    //       arabic_description:
    //         "الكتاب كائن حي، يتحول البطل من إنقاذ العالم من السقوط في المعنى، لإنقاذ أبطال الروايات من حكم الإعدام، بعدما أصابته لعنة القراءة.",
    //     },
    //     {
    //       arabic_title: "60 نصيحة لتقدير الذات",
    //       arabic_description:
    //         "تقدير الذات هو المكان الضروري للنجاح والسعادة في حياتنا، حينما نمتلكها نشعر بشعور جيد حيال أنفسنا، ونشعر بالهدوء والثقة والسيطرة، وأن كل شيء ممكن، عندما نفقدها نعلم ذلك على الفور، تتلاشى ثقتنا بأنفسنا، ويبدأ كل شيء بالإخفاق.",
    //     },
    //     {
    //       arabic_title: "آيبون",
    //       arabic_description:
    //         "في هذه الحكاية سنصارع أمواجًا من المشارع مع عراك كبير بين القلب والعقل، والحب والكراهية، والحنين والانتقام.",
    //     },
    //     {
    //       arabic_title: "أسرار عالم الطيران",
    //       arabic_description:
    //         "كيف تقلع الطائرة؟ ما أسباب المطبات الجوية؟ لماذا وجبات الطيارين مختلفة؟ ما سبب تأخير الرحلات؟ ماذا يحدث لو فقدت الطائرة جميع محركاتها؟ لماذا نفتح النوافذ قبل الإقلاع؟",
    //     },
    //     {
    //       arabic_title: "أسرار عالم الطيران 2",
    //       arabic_description:
    //         "لماذا لا تعبّأ خزانات الوقود بالكامل في كل رحلة؟ لماذا يوجد مطارات فيها أكثر من مدرج واحد؟ ما مخاطر العواصف الرعدية؟ ما الاختلاف بين الإجراءات الطبيعية وغير الطبيعية؟ هل الغبار يؤثر على الطائرة؟ كيف تُشغل المحركات؟ كيف التعامل مع الأعطال الفنية؟",
    //     },
    //     {
    //       arabic_title: "أسرار قابلة للنشر",
    //       arabic_description:
    //         "ما بين تجارب وقصص من واقع الاستشارات، وما يسطره لنا العلم، في الكتاب عصارة استنتاجات علمية لتكون بدايتك لحياة بفكر جديد ومختلف لنبحر معا برحلة في النفوس مع أسرار قابلة للنشر.",
    //     },
    //     {
    //       arabic_title: "أمومتي صناعة جيل",
    //       arabic_description:
    //         "الأم: هي اللحظة التي قررت فيها السماح لقلبك أن يخرج من مكانه. الأم: عطاء بلا حدود، تعطي بلا قيد أو شروط. الأم: هي الراعي الرسمي والمالك الأول لمشروع صناعة جيل. الأم: الإنسانة الوحيدة التي يرضيها صلاحك ويسعدها نجاحك. الأم: التي أحبتك وخافت عليك وغذتك قبل أن تراك. الأم: االإنسانة العظيمة التي وصى بها الله سبحانه وتعالى ووصى بها رسوله صلى الله عليه وسلم. الأم: هي أمي وأمك، وأنا وأنت.",
    //     },
    //     {
    //       arabic_title: "أمومتي تربية بحب",
    //       arabic_description:
    //         "الأم: ليست شهادات ولا دراسات، كثير من الأمهات تعليمهن محدود، لكنهن عالمات في تربية أولادهن. الأم: هي الوظيفة التي تشمل كل مجالات الحياة من أكبرها إلى أصغرها. الأم: التي أحبتك وخافت عليك وغذتك قبل أن تراك. الأم: الإنسانة العظيمة التي وصى بها الله سبحانه وتعالى ووصى بها رسوله صلى الله عليه وسلم. الأم: هي أمي وأمك، وأنا وأنت.",
    //     },
    //     {
    //       arabic_title: "إتيكيت الحب",
    //       arabic_description:
    //         "لست مجبرة أن ألقب بالمرأة الخارقة دائمًا، لكني قوية جدًّا، النجاح ليس سهلًا، فأنا أحزن وأمارس كل تقلباتي العاطفية بشكل طبيعي، وأعود أقوى وأفخم من السابق، وهذه التجارب الحياتية التي صنعت مني إمرأة شامخة طريقها خصب جدًّا، كما أن الحياة علمتني ان أحترم روحي وكل قناعاتي ولا أدخل في مهاترات مع أرواح لا تشبهني، وذلك حفاظا على جمال روحي من الاستنزاف من أشخاص لم يخلقوا ليعبثوا معي، لا تشبهين أحدًا يا لوغانية.",
    //     },
    //     {
    //       arabic_title: "إتيكيت الروح",
    //       arabic_description:
    //         "عشق الروح وتقديرها والتعامل معها بإيتيكيت وإيجابية. فالإيجابية علامة من علامات الجمال، والروح الجميلة هي التي تستمتع بيومها حتى ولو كانت تفاصيله بسيطة. والكتاب يساعدنا على تدريب الروح على أن تكون مكتفية ومؤهلة لاختيار العلاقات السليمة الناضجة، والابتعاد عن المزعجة منها، وإعادة ترتيب الأرواح في حياتنا؛ لأن من الذوق أن نقرب منا الأرواح التي تسعدنا، ونبعد عنا الأرواح التي تزعجنا.",
    //     },
    //     {
    //       arabic_title: "إتيكيت الروح 2",
    //       arabic_description:
    //         "لا تحزن إذا وجدت تجاعيد وجهك تزداد، ولا تظن أنك كبرت إذا لاحظت شعرك الأبيض يزهر، أعشقهم، فكل خط تغير أو تجعيدة بوجهك ظهرت لها دور كبير في حياتك، فهي ممزوجة بذكريات سهرك وتعبك وإنجازاتك، خسارة كبيرة إذا لم تعشقها، اعشق روحك ودللها فهي تستحق، ستخرج من كل قصة، مهما كان عمرك، بخبرة تجعل قلبك أقوى وعقلك أذكى لمواجهة تجربة جديدة، إلى أن تصل لقصة مشوقة تنمي لقلبك عقلًا ينعش روحك، لا تستسلم.",
    //     },
    //     {
    //       arabic_title: "الخزراء ",
    //       arabic_description:
    //         "رواية فانتازيا تقع أحداثها بين عالم البشر وعالم الجن. فقد وجد نفسه في عالم آخر يجهله تمامًا، يناقض عالمه في نواحي ويجانسه في نواح أخرى، غير أنه عالم يغاير عالمه، عالم يجهل معناه إن كان أصلًا له معنى، عالم يكاد يكون بعدا آخرًا، فانقلبت موازينه رأسًا على عقب حتى انتشلته إحداهن فاعتدلت موازينه.",
    //     },
    //     {
    //       arabic_title: "بوصلة طفلي",
    //       arabic_description:
    //         "بوصلة الشخصية هي علم دقيق في تحديد نمط كل شخصية، من حيث صفاتها، وطرق تفكيرها، فالبوصلة تحتوي على جهات أصلية شمال وشرق وجنوب وغرب، ومنها تتفرغ الجهات الفرعية، وتوجهنا لمعرفة الطريق الصحيح لاتجاهنا، كذلك بوصلة الشخصية لكل جهة صفات تشملها وسلوك يغلب على الشخص، فنعلم كيف يفكر، وما ردة فعله، وما الطريقة المثلى للتعامل معه، وركزت بدراستي هذه على طفولة كل شخصية، أبحرت بمعالمها من دقة الملاحظة حول تصرفات كل نمط.",
    //     },
    //     {
    //       arabic_title: "حديث العصافير",
    //       arabic_description:
    //         "حظيت بأغصان كثيفة شكلت معها لوحات رائعة في كل لحظة من عمري، وليس عيبًا أن أصارح نفسي بهذه الحقيقة؛ لأننا في النهاية بشر تجمعنا ذات الحكايات الغريبة، ولكن تختلف في بعض التفاصيل التافهة كعدد الأغصان المرتبطة ببعضها، والقوة التي من خلالها سيستمر ذلك الرابط.",
    //     },
    //     {
    //       arabic_title: "دستور الأنثى",
    //       arabic_description:
    //         "الأنثى الموجودة بين سطوري هي أنثى قوية شامخة وواقعية، ولكنها إيجابية راقية، لا تترك نفسها فريسة للواقع مهما كانت قسوته، وإنما تعلو عليه لتنطلق نحو هدفها وتطوير ذاتها، فهذه هي الأنثى الحقيقية.",
    //     },
    //     {
    //       arabic_title: "فنانو الوقت",
    //       arabic_description:
    //         "يعمل التخطيط على تجسير الفجوة بين ما هو قائم فعلًا، أو ما نحن عليه الآن، وبين ما نستهدف تحقيقه، إنه أسلوب يصل المستقبل بالحاضر، ومع ذلك فنحن لا نحبذه؛ لأنه نشاط معقد يتطلب تفكيرًا في المستقبل بطريقة منظمة، واستشرافًا له، وتوقعًا للأحداث. ويستمد تخطيط الوقت أهميته من حقيقة أساسية، هي: أن المهام أو الأنشطة كثيرة، وأن هنا دائمًا أشياء لا بد من القيام بها، وبالتالي فإن الوقت هو الإطار الحرج المحدود الذي لا يمكن تمديده أو توسيعه ليستوعب كل ما نريد أن نحدثه فيه.",
    //     },
    //     {
    //       arabic_title: "كيف تنجح في وسائل التواصل الاجتماعي",
    //       arabic_description:
    //         "لا يوجد قواعد لاستخدام وسائل التواصل الاجتماعي، فلا تزال اللعبة جديدة، والساحة مفتوحة، لكن توجد خبرة من خاض جولات عديدة في هذا المجال، بعضها بات بالفشل، وبعضها نجح، واستمر بالتجربة والخطأ إلى أن وصل إلى هدفه، أما الغاية من وراء هذه البرامج والأهداف فلا تعد ولا تحصى. لا يوجد حدود لما يمكن أن تصل إليه في هذا العالم الكبير!",
    //     },
    //     {
    //       arabic_title: "نورسين",
    //       arabic_description:
    //         "لا أعلم كيف أتيت إلى هنا؟ ولماذا أنا تحديدًا في هذا المكان؟ وما الذي يحدث من حولي! كل ما أعلمه أنني كنت نائمًا وفتحت عيني على صراخ أحدهم، لأكتشف بعد ذلك أن هناك فاجعة حدثت، ما هي تلك الفاجعة؟!",
    //     },
    //     {
    //       arabic_title: "وما غَوَتْ",
    //       arabic_description:
    //         "أين كانت النهاية! قد يتبادر إلى ذهنك أثناء القراءة أنها كانت في برشلونة، عندما التقت عينا عبد الرحمن وأميرة في المقهى الصغير. أو هناك في ميونيخ عندما قرر سليم الضابط البحري الارتباط بسعاد صانعة القماش. أو ربما في الولايات المتحدة الأمريكية عندما قرر يوسف الهجرة للبحث عن كيانه، والزواج من إيلينا البائسة. أو ربما في لندن عندما اختار نادر أتعس الأشخاص حظًّا وأكثرهم شرفًا ليكسر مبادئه ويعيش في ظل الخيانة. أو ربما في السعودية، عندما تمرد منصور على سطوة أبيه، وغادر حجره. ستعرف الإجابة حتمًا مع انتهائك من قراءة هذه اللوحة الجميلة، ولكن لا تنس أن تكرر السؤال بصيغة أكثر صدقًا... أين كانت البداية حقًّا؟!",
    //     },
    //     {
    //       arabic_title: "يوم مع طيار",
    //       arabic_description:
    //         "مهنة الطيار من المهن الغامضة، الكثير من الناس إما لا يعرفون عنها شيئًا أو لديهم معلومات مغلوطة، من خلال كتاب يوم مع طيار سنضع النقاط على الحروف، ستعيش مع الطيار مشواره المهني، وتأثير ذلك على تطورات حياته المهنية والشخصية.",
    //     },
    //     {
    //       arabic_title: "أبو الملوك السلطان الغازي عثمان بن أرطغرل",
    //       arabic_description:
    //         "في زمان ضلت فيه الروح الإسلامية أجساد أبناءها، فأصبحنا نخجل من هويتنا وعروبتنا، حتى ألسنتنا هجرتها الحروف العربية. في زمان افتقرنا فيه إلى القدوة الحسنة، واستبدلناها بمن أجادوا المكر والخداع. في زمن تجاهلنا فيه أجدادنا وبطولاتهم. في هذا الزمان، كان لا بد من محاولة إفاقة وإنعاش الرئة العربية وهي تحتضر، بعدما أدمنت استنشاق هواء الغرب المسمم. محاولة لرفع الروح المعنوية لكل عربي مسلم. فبسم الله، ثم بسلاح القلم، نحاول تصحيح ما شوهه الحاقدون للأمة الإسلامية.",
    //     },
    //     {
    //       arabic_title: "أحمد ديدات سفير العهد الأخير",
    //       arabic_description:
    //         "(هاتوا برهانكم) أول خطوة فطرية مشاها أحمد ديدات في سبل المجادلة مع الآخر المختلف عقيدة وسلوكًا، حين كان يبيعه الملح وهو ابن ستة عشر عامًا. (تعالو إلى كلمة سواء بيننا وبينكم) قانون سرمدي بني على مترتباته الاجتماع تحت سقف واحد مع الآخر الذي اقتحم على طرائده منازلهم، يراوغهم ويحملهم على التسليم بمنطقة الدوجماتي.",
    //     },
    //     {
    //       arabic_title: "المهاجرون عن الذنوب",
    //       arabic_description:
    //         "لا.. لست وحدك عندما يسعفك العقل بالحضور.. ما كانوا يومًا وحدهم رغم اختلاف الألوان والحكايات.. كان العقل طريقهم للنجاة في رحلة الحياة والتجارب، ذلك الطريق الذي حق على الله أن يكون في آخره انتظار السائرين إليه بآيات الدلالة وإشارات اليقين، فما ضاعوا ولن تضيع أنت.. كن على يقين.",
    //     },
    //     {
    //       arabic_title:
    //         "تربية محمد صلى الله عليه وسلم للإنسان من الطفولة حتى الكهولة",
    //       arabic_description:
    //         "هامش الحياة قد يقتل فيك إنسانيتك، ويقيدك عن السير نحو هدفك، ويجعلك أسير النفس والملذات، فكن ضمن دفتي كتاب يأخذ بيدك نحو بر الأمان، فتجعل رسول الرحمة قدوتك؛ لتتعلم كيف تصنع جيلًا صالحًا وشبابًا نافعًا، وتعرفك كيف تتعامل مع الآخرين من أطفال ونساء وشيب وشباب كما كان يفعل المصطفى صلى الله عليه وسلم.",
    //     },
    //     {
    //       arabic_title: "تهذيب الذات كما ورد في بعض الآيات",
    //       arabic_description:
    //         "في هذا الكتاب، قد تجد ما يؤنس روحك تارة، ويجعلك تعيد النظر في حياتك تارة أخرى، فقد تبتسم في موضع، وتخنقك العبرة في آخر، والعبرة في ذلك أنني وقفت على جوانب النفس الإنسانية بكل توجهاتها وأشكالها، وحاولت أن أبحث عن بذر الإنسان فينا لننميها بالتقوى والصلاح، وننفض عن ذواتنا الضياع والتخبط، ونتمسك بعروة الخير؛ لنكون أناسًا مؤثرين وقادرين أكثر من كوننا متأثرين خانعين.",
    //     },
    //     {
    //       arabic_title: "حروف الصحابة",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "ذاكر نايك",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "رحمت الله الهندي",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "صحابة رسول الله صلى الله عليه وسلم",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "عبد الرحمن السميط",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "عبد الواحد بلافيتشيني",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "كن بخير",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "لأنه محمد صلى الله عليه وسلم",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "مراهقون غيروا وجه العالم",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "مغنون أصبحوا دعاة",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "منسي موسى",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "نساء يسكن الذاكرة",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "هجروا اليهود واعتنقوا الإسلام",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "40 زنزانة ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "المدينة الوحيدة: مغامرات في فن البقاء وحيدًا",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "عدوي اللدود",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "زينة حياتي",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "كل الطرق لا تؤدي إليك",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "اضطراب: حكاية ميت لا أعرفه",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "إدارة الأولويات الأهم أولًا",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "ابق قويًّا ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "اتخاذ القرار وحل المشكلات",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "الأشياء الأولى أولًا",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "التغلب على الخجل: قهر الخجل والقلق الاجتماعي",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "بدون قلق",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "تعزيز تقدير الذات",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "حينما كان للشوارع أسماء",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "الأطفال المزعجون",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "فلسطين التاريخ المصور",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "مساء الفراشة ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "أصابع مريم ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "إضاءات العتمة الأخيرة ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "الدقيقة الواحدة والعشرون ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "السكابندو ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "المطمورة",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "اهرب من ظله ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "جسدك هنا وطني هناك ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "جغرافي يبحث عن أرض الشمس ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "ديم الحنين ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "ريشات من أجنحة السفر ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "ساق الفرس",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "سبع شخصيات تسمم حياتكم",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "كوزيما ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "مذكرات متسولة ",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "وادي الشمس",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "وكشفت رأسي",
    //       arabic_description: "0",
    //     },
    //     {
    //       arabic_title: "أسرار قوة الجاذبية الأنثوية ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "أنا أقدر ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الأرواح تتبادل القبل",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الباغ ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الجنة في مكانك ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "السراديب الخلفية",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "العائدون من البرزخ ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "النيل الطعم والرائحة",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "باقون هنا ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "بدون ذكر أسماء ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "جريمة في مدرسة الثانوية ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "حدث في SOHO",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "رحلة الهلاك ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "شو",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "لما يندمل ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "مساءات وردية ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "هناك إله",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الأمنيات لا تموت",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الجوزاء وعناقيد الضوء ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "غوى",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "فخ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "سيناريو ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "ابتسامات ودموع",
    //       arabic_description:
    //         "لا تحدثني عن الحب إن لم تكن غارقًا فيه، بل حدثني عن وحدتك، سأصدقك حينها أكثر...\nإياكَ تخبرني عن رونق المطر ورائحته حينما يقبّل حجارة الأرض وأنت جالسٌ أمام مدفأتك تحتمي من قطراته.. بل افتح بابك واخرج إليه، افرد ذراعيك واستقبل قبلات قطرات المطر على جسدك... أركض تحت قطراته الندية لتبتل ملابسك بالكامل وحينها فقط... لو أخبرتني بأن المطر رائع سأصدق حروفك.\nفالكلمة الصادقة هي ما تلامس القلب مباشرةً وتخترقه دون إنذار..\nلربما تظنها مجرد حكايا كتبتها ..\nلكن..قد ينزف الورق ذات يومٍ من قسوة الواقع، قد يئن من آلام أصحابه..\nوبالنهاية، الأدب هو مرآة الواقع، انعكاسٌ لهواجسنا وتجاربنا وآلامنا وآمالنا.",
    //     },
    //     {
    //       arabic_title: "إكسير الشباب ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الغريبة",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "بذات الطبشور ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "بين قلبي وأمي ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "حياة مرت من هنا ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "رسائل شهرزاد",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "سيمفونية سبيرز",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "شادن",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "عازف على وتر الزمن",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "عجين الدم ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "عندما أحتسي قهوتي ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "فوق الخد شامة ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "قهوتي مرّة",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "لا خطاوينا ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "ليس للنشر ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "مجموعة إنسان ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "وتتعانق العينان ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "وحشت الدار ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "أربعة وطاولة",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "بين الحب والسراب",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "المستنقع ",
    //       arabic_description: " ",
    //     },
    //     {
    //       arabic_title: "بأي ذنب قتلت ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "اعترافات متجمدة ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "أكون ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الشهود الصامتون ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "بقايا مدينة ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "حكاية غرام ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "زنزانة 760",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "سوشي ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "عندما كنت عربيًّا ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "قريبا ستمطر",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "لن تستطيع معي صبرًا ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "ليس غدًا ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "ملحد في المحراب ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "نسمة حب ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "وتمضي الحياة ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "أردت أن أبصر",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "أن تجد الحب في مكتبة",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "المنبوذ ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "ثورة أيرلن ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "نظارتي الوردية ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "أذن الجدران الصغيرة",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "لم أعد إنجليزيًّا يا أبي",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "مهاجر في زمن الجدري ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "وما تلك بيمينك",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "جزيرة الكنز ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "دراكولا",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "صاحب الظل الطويل",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "آخر الوصايا الصغيرة ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "ارقدي بسلام",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "حالات نادرة",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "زاجل ",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "لماذا ينظرون إليَّ؟",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "التجاوب مع الضغوط",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الحياة هي ما نصنع: اكتشف طريقك نحو الشعور بالرضا",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title:
    //         "تخلص من ولكن: كيف تضع حدًّا لتدمير الذات وتدافع عن نفسك",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title:
    //         "كسر الدائرة حرر نفسك من إدمان وهواجس المواد الإباحية والشعور بالخزي",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "لماذا ينظرون إليَّ؟",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الحب لا يموت أبدًا",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "لا تُمحى",
    //       arabic_description:
    //         "يضم هذا الكتاب عشرة قصص، أحداثها في اماكن وأزمنة مختلفة، ما يجمعها هو أنها كلها تؤكد أن الحب نعمة من الله، كتب لها ألا تموت أبدًا طالما توجد قلوب طاهرة.",
    //     },
    //     {
    //       arabic_title: "عزيزي أنا",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "لا تصدق الكذبة: فلسفة الحب في زمن الكراهية",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "الحاجات النفسية للطفل",
    //       arabic_description: "",
    //     },
    //     {
    //       arabic_title: "هايدي",
    //       arabic_description: "",
    //     },
    //   ];
    //   let i = 2;
    //   data.forEach(async (element) => {
    //     element["author"] = i + 1;
    //     const { data } = await ApiService.post(`items/products`, element);
    //   });
    // },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          if (this.image) {
            // send file
            let formData1 = new FormData();
            formData1.append("data", this.image[0]);
            // await this.postFile(formData1);
            const file = await ApiService.postFile("files", formData1, 0, null);
            // send post req
            this.formData["image"] = file.data.data.id;
          }
          if (this.logo) {
            // send file
            let formData2 = new FormData();
            formData2.append("data", this.logo[0]);
            // await this.postFile(formData1);
            const file1 = await ApiService.postFile(
              "files",
              formData2,
              0,
              null
            );
            // send post req
            this.formData["logo"] = file1.data.data.id;
          }
          if (this.record) {
            const { data } = await ApiService.update(
              `items/${this.collection}`,
              this.formData.id,
              this.formData
            );
          } else {
            const { data } = await ApiService.post(
              `items/${this.collection}`,
              this.formData
            );
          }
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  async mounted() {
    if (this.record) {
      Object.keys(this.formData).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.formData[element] = this.record[element].id;
          } else {
            this.formData[element] = this.record[element];
          }
        }
      });
      if (this.formData.image) {
        const { data } = await ApiService.getFile("files", this.formData.image);
        this.file = data.data;
      }
      if (this.formData.logo) {
        console.log(this.formData.logo);
        const { data } = await ApiService.getFile("files", this.formData.logo);
        this.file1 = data.data;
      }
    }
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
